/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import AuthUser from "../components/AuthUser";
import Spinner from "../components/Spinner";

import bg1 from '../assets/images/bg/sobre-banner.jpeg'

import Navbar from "../components/navbar";
import AboutImage from "../components/aboutImage";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import CtaTwo from "../components/cta/ctaTwo";

import { medicalServices, doctorData } from "../data/data";


import {
    RiEyeFill, RiPsychotherapyFill, RiStethoscopeFill, RiCapsuleFill, RiMicroscopeFill, RiPulseFill, RiEmpathizeFill, RiMindMap, RiMentalHealthLine, RiAppleLine, RiInfraredThermometerLine, RiCapsuleLine, GrUserPolice, RiMicroscopeLine, TbAmbulance, RiShieldCrossLine, RiBriefcase4Line, FiAirplay, MdOutlineFlip, PiTelegramLogoBold, RiMailStarLine, RiHourglassLine, RiHeartLine, RiHeartPulseLine, RiStethoscopeLine, RiVirusLine, RiDossierLine, RiEye2Line, FiUser, FiMail, VscBook, MdFormatItalic, GiMedicalDrip, FiMapPin, FiBriefcase, LuFileCheck, MdOutlineStorefront, LuAward, LiaFileInvoiceDollarSolid, FiThumbsUp, FiPhone, RiArrowRightLine,
    RiMapPin2Line,
    FiFacebook,
    FiLinkedin,
    FiGithub,
    MdOutlineEventNote,
    FiCalendar,
    FiClock,
    FiHeart, FiEye, RiUser2Line, FiTwitter, FiArrowRight
} from "./../assets/icons/vander";
import ServicosIcons from "../components/ServicosIcons";

const icons = [
    { nome: 'olhos', icon: RiEyeFill },
    { nome: 'cabeça', icon: RiPsychotherapyFill },
    { nome: 'ostetoscopio', icon: RiStethoscopeFill },
    { nome: 'capsula', icon: RiCapsuleFill },
    { nome: 'microscopio', icon: RiMicroscopeFill },
    { nome: 'cardiologia', icon: RiPulseFill },
    { nome: 'coracao', icon: RiEmpathizeFill },
    { nome: 'celulas', icon: RiMindMap },
    { nome: 'cerebro', icon: RiMentalHealthLine },
    { nome: 'apple', icon: RiAppleLine },
    { nome: 'fratura', icon: RiInfraredThermometerLine },
    { nome: 'calendar', icon: FiCalendar },
    { nome: 'doctor', icon: GrUserPolice },
    { nome: 'pasta', icon: RiBriefcase4Line },
    { nome: 'projetor', icon: FiAirplay },
    { nome: 'janela', icon: MdOutlineFlip },
];


export default function AboutUs() {

    const { http, url_root } = AuthUser();

    const getIconByName = (name) => {
        const iconObject = icons.find(icon => icon.nome == name);
        return iconObject ? iconObject.icon : null;
    };

    let IconeCalendar = getIconByName("calendar");


    const [unidades, setUnidades] = useState([]);
    const [doctores, setDoctores] = useState([]);
    const [paragrafos, setParagrafos] = useState([]);
    const [directores, setDiretores] = useState([]);

    const [bgBackup, setBG] = useState(bg1);
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Lógica para carregar cursos apenas se a permissão permitir

        const fetchData = async () => {
            try {
                // Chama a função assíncrona

                await getInfo();

            } catch (error) {
                setLoading(false);
                console.error('Erro ao obter info:', error);
            }
        };

        // Chama a função fetchData() para iniciar o processo
        fetchData();

    }, []);


    useEffect(() => {
        console.log('Unidades:', unidades);
        setUnidades(info[0]?.unidades?.slice(0, 14) || []);
        setDoctores(info[0]?.doctores?.slice(0, 14) || []);
        setParagrafos(info[0]?.paragrafos || []);
        setDiretores(info[0]?.diretores || []);
    }, [info]);


    const getInfo = async () => {

        try {

            setLoading(true); // Set loading to true when the request starts

            const res = await http.get('/site-pagina/2');

            console.log("info :");
            console.log(res.data);

            setInfo(res.data);

            console.log('Doctores');
            console.log(info[0]?.doctores);
            console.log('Consultas');
            console.log(info[0]?.consultas);

            setBG(url_root + "/storage/" + info[0]?.banner?.imagem);

            setUnidades(info[0]?.unidades?.slice(0, 14) || []);
            setDoctores(info[0]?.doctores?.slice(0, 14) || []);
            setParagrafos(info[0]?.paragrafos || []);
            setDiretores(info[0]?.diretores || []);

            setLoading(false);

        } finally {
            setLoading(false); // Set loading to false when the request is complete (either success or error)
        }
    };




    return (
        <>
            <Navbar manuClass="navigation-menu nav-light nav-left" containerClass="container" />


            {info[0]?.banner != null ? <>
                <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${url_root + "/storage/" + info[0]?.banner?.imagem})`, backgroundPosition: 'center' }}>
                    <div className="bg-overlay bg-overlay-dark"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-12">
                                <div className="section-title text-center">
                                    <h3 className="sub-title mb-4 text-white title-dark"> {info[0]?.banner?.designacao}{" "}</h3>
                                    <p className="para-desc mx-auto text-white-50">{info[0]?.banner?.descricao}{" "}</p>

                                    <nav aria-label="breadcrumb" className="d-inline-block mt-3">
                                        <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                                            <li className="breadcrumb-item"><Link to="/">INICIO</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Sobre Nós</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </> : <></>}




            <div className="position-relative">
                <div className="shape overflow-hidden text-color-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="section">


                <div className="container mt-60">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-6">
                            <div className="section-title me-lg-5">

                                <span className="badge rounded-pill bg-soft-primary">{info[0]?.paragrafos[0]?.titulo}{" "}</span>
                                <h4 className="title mt-3 mb-4">{info[0]?.paragrafos[0]?.descricao}</h4>

                                <p className="para-desc text-muted">{info[0]?.paragrafos[1]?.descricao}</p>

                                <p className="para-desc text-muted">{info[0]?.paragrafos[2]?.descricao}</p>

                            </div>
                        </div>
                        <AboutImage imagem={url_root + "/storage/" + info[0]?.paragrafos[0]?.imagem} videoId={info[0]?.paragrafos[0]?.icone} colClass="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0" />
                    </div>
                </div>


                <CtaTwo className="mt-60" paragrafos={paragrafos} />


                <div className="container mt-100 mt-60 ">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title mb-4 pb-2 text-center">
                                <span className="badge rounded-pill bg-soft-primary mb-3">{info[0]?.paragrafos[6]?.titulo}</span>
                                <h4 className="title mb-4">{info[0]?.paragrafos[6]?.descricao}</h4>
                                <p className="text-muted mx-auto para-desc mb-0">{info[0]?.paragrafos[7]?.descricao}</p>
                            </div>
                        </div>
                    </div>


                    <ServicosIcons medicalServices={info[0]?.consultas} />

                </div>

                <div className="container mt-100 mt-60">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center mb-4 pb-2">
                                <h4 className="title mb-4">{info[0]?.paragrafos[8]?.titulo}</h4>
                                <p className="text-muted mx-auto para-desc mb-0">{info[0]?.paragrafos[8]?.descricao}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        {" "}
                        {info[0]?.directores?.slice(0, 4).map((item, index) => {
                            return (
                                <div
                                    className="col-xl-3 col-lg-3 col-md-6 mt-4 pt-2"
                                    key={index}
                                >
                                    <div className="card team border-0 rounded shadow overflow-hidden">
                                        <div className="team-img position-relative">
                                            <img src={url_root + "/storage/" + item?.fotografia} className="img-fluid" alt="" />
                                            <ul className="list-unstyled team-social mb-0">
                                                <li>
                                                    {" "}
                                                    <Link
                                                        to="#"
                                                        className="btn btn-icon btn-pills btn-soft-primary"
                                                    >
                                                        {" "}
                                                        <FiFacebook className="icons" />{" "}
                                                    </Link>
                                                </li>
                                                <li className="mt-2 ms-0">
                                                    {" "}
                                                    <Link
                                                        to="#"
                                                        className="btn btn-icon btn-pills btn-soft-primary"
                                                    >
                                                        {" "}
                                                        <FiLinkedin className="icons" />{" "}
                                                    </Link>
                                                </li>
                                                <li className="mt-2 ms-0">
                                                    {" "}
                                                    <Link
                                                        to="#"
                                                        className="btn btn-icon btn-pills btn-soft-primary"
                                                    >
                                                        {" "}
                                                        <FiGithub className="icons" />{" "}
                                                    </Link>
                                                </li>
                                                <li className="mt-2 ms-0">
                                                    {" "}
                                                    <Link
                                                        to="#"
                                                        className="btn btn-icon btn-pills btn-soft-primary"
                                                    >
                                                        {" "}
                                                        <FiTwitter className="icons" />{" "}
                                                    </Link>
                                                </li>
                                            </ul>{" "}
                                        </div>{" "}
                                        <div className="card-body content text-center">
                                            <Link
                                                 to={"/doctor-profile/"+item?.id}
                                                className="title text-dark h5 d-block mb-0"
                                            >
                                                {" "}
                                                {item?.name}{" "}
                                            </Link>{" "}
                                            <small className="text-muted speciality">
                                                {" "}
                                                {item?.nivel_academico}{" "}
                                            </small>{" "}
                                        </div>{" "}
                                    </div>{" "}
                                </div>
                            );
                        })}{" "}
                    </div>{" "}


                    <Link to={`/doctor-team-two`} className="link btn btn-primary mt-100">Ver todos <i className="mdi mdi-chevron-right align-middle"></i></Link>


                </div>
            </section>



            <Footer footer={info[0]?.footer} consultas={info[0]?.consultas} />
            <ScrollTop />

            {
                loading ? <>
                    <Spinner />
                </> : <></>
            }

        </>
    )
}