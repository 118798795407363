import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

import { RiEyeFill, RiPsychotherapyFill, RiStethoscopeFill, RiCapsuleFill, RiMicroscopeFill, RiPulseFill, RiEmpathizeFill, RiMindMap, RiMentalHealthLine, RiAppleLine, RiInfraredThermometerLine, RiCapsuleLine, GrUserPolice, RiMicroscopeLine, TbAmbulance, RiShieldCrossLine, RiBriefcase4Line, FiAirplay, MdOutlineFlip, PiTelegramLogoBold, RiMailStarLine, RiHourglassLine, RiHeartLine, RiHeartPulseLine, RiStethoscopeLine, RiVirusLine, RiDossierLine, RiEye2Line, FiUser, FiMail, VscBook, MdFormatItalic, GiMedicalDrip, FiMapPin, FiBriefcase, LuFileCheck, MdOutlineStorefront, LuAward, LiaFileInvoiceDollarSolid, FiThumbsUp, FiPhone, RiArrowRightLine, FiCalendar } from './../assets/icons/vander';

const icons = [
    { nome: 'olhos', icon: RiEyeFill },
    { nome: 'cabeça', icon: RiPsychotherapyFill },
    { nome: 'ostetoscopio', icon: RiStethoscopeFill },
    { nome: 'capsula', icon: RiCapsuleFill },
    { nome: 'microscopio', icon: RiMicroscopeFill },
    { nome: 'cardiologia', icon: RiPulseFill },
    { nome: 'coracao', icon: RiEmpathizeFill },
    { nome: 'celulas', icon: RiMindMap },
    { nome: 'cerebro', icon: RiMentalHealthLine },
    { nome: 'apple', icon: RiAppleLine },
    { nome: 'fratura', icon: RiInfraredThermometerLine },
    { nome: 'calendar', icon: FiCalendar },
    { nome: 'doctor', icon: GrUserPolice },
  ];


export default function ServicosIcons({ medicalServices }) {

    const getIconByName = (name) => {
        const iconObject = icons.find(icon => icon.nome == name);
        return iconObject ? iconObject.icon : null;
    };

    return (
        <>
            <div className="row">
                {" "}
                {medicalServices?.slice(0, 8).map((item, index) => {

                    //let Icon = item.icon;
                    let Icon = getIconByName(item?.idioma);

                    return (
                        <div className="col-xl-3 col-md-4 col-12 mt-5" key={index}>
                            <div className="card features feature-primary bg-transparent border-0">
                                <div className="icon text-center rounded-md">

                                    {Icon ?<><Icon className="h3 mb-0" /></>:<></>}
                                    

                                </div>{" "}
                                <div className="card-body p-0 mt-3">
                                    <Link
                                        onTouchMove={"/servico-detail/"+item?.id}
                                        className="title text-dark h5"
                                    >
                                        {" "}
                                        {item?.designacao}{" "}
                                    </Link>{" "}
                                    <p className="text-muted mt-3"> {item?.descricao_curta} </p>{" "}
                                    <Link to={"/servico-detail/"+item?.id} className="link">
                                        {" "}
                                        Ler mais <RiArrowRightLine className="align-middle" />{" "}
                                    </Link>{" "}
                                </div>{" "}
                            </div>{" "}
                        </div>
                    );
                })}{" "}

            </div>{" "}

        </>
    )


}


