/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import AuthUser from "../components/AuthUser";
import Spinner from "../components/Spinner";

import blog from '../assets/images/blog/single.jpg'

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";

import bg1 from '../assets/images/bg/servico-banner-1.jpeg'

import { blogData, commentData, recentPost } from "../data/data";

import { FiFacebook, FiInstagram, FiTwitter, FiLinkedin, FiGithub, FiGitlab, FiYoutube, FiCalendar, FiClock } from '../assets/icons/vander'

import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

export default function BlogDetail() {

    const { id } = useParams();

    const { http, url_root } = AuthUser();

    const [unidades, setUnidades] = useState([]);
    const [outrasNoticias, setOutrasNoticias] = useState([]);
    const [doctores, setDoctores] = useState([]);
    const [paragrafos, setParagrafos] = useState([]);
    const [consultas, setConsultas] = useState([]);
    const [unidade, setUnidade] = useState([]);

    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Lógica para carregar cursos apenas se a permissão permitir

        const fetchData = async () => {
            try {
                // Chama a função assíncrona

                await getInfo();

            } catch (error) {
                setLoading(false);
                console.error('Erro ao obter info:', error);
            }
        };

        // Chama a função fetchData() para iniciar o processo
        fetchData();

    }, [id]);


    useEffect(() => {
        setConsultas(info[0]?.consultas || []);
        setOutrasNoticias(info[0]?.outrasNoticias || []);
        setUnidade(info[0]?.unidade || {});
        setParagrafos(info[0]?.paragrafos.slice(0, 14) || []);
    }, [info]);


    const getInfo = async () => {

        try {

            setLoading(true); // Set loading to true when the request starts

            const res = await http.get('/site-pagina-single/7/' + id);

            console.log("info :");
            console.log(res.data);


            setInfo(res.data);

            setConsultas(info[0]?.consultas || []);
            setOutrasNoticias(info[0]?.outrasNoticias || []);
            setDoctores(info[0]?.doctores?.slice(0, 14) || []);
            setUnidade(info[0]?.unidade || {});
            setParagrafos(info[0]?.paragrafos.slice(0, 14) || []);
            setUnidades(info[0]?.unidades || []);

            setLoading(false);

        } finally {
            setLoading(false); // Set loading to false when the request is complete (either success or error)
        }
    };

    let settings = {
        container: '.slider-range-three',
        items: 3,
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 400,
        gutter: 24,
        responsive: {
            992: {
                items: 3
            },

            767: {
                items: 2
            },

            320: {
                items: 1
            },
        },
    };

    let data = blogData.find((blog) => blog.id === parseInt(id))

    return (
        <>
            <Navbar manuClass="navigation-menu nav-left nav-light" containerClass="container" />


            {info != null ? <>
                <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${url_root + "/storage/" + info[0]?.noticia?.imagem})`, backgroundPosition: 'center' }}>
                    <div className="bg-overlay bg-overlay-dark"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-12">
                                <div className="section-title text-center">
                                    <h3 className="sub-title mb-4 text-white title-dark">{info[0]?.noticia?.titulo}</h3>
                                    <p className="para-desc mx-auto text-white-50">{info[0]?.noticia?.descricao}</p>

                                    <nav aria-label="breadcrumb" className="d-inline-block mt-3">
                                        <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                                            <li className="breadcrumb-item"><Link to="/">INICIO</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Noticia Info</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </> : <></>}


            <div className="position-relative">
                <div className="shape overflow-hidden text-color-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-lg-7">
                            <img src={url_root + "/storage/" + info[0]?.paragrafos[0]?.imagem} className="img-fluid rounded shadow" alt="" />
                            <p className="text-muted mt-4">{info[0]?.paragrafos[0]?.descricao}</p>

                            {info[0]?.paragrafos?.slice(1, 20).map((item, index) => {
                                return (
                                    <p className="text-muted" key={index}>{item?.descricao}</p>
                                )
                            })}

                            {/* <h5 className="card-title mt-4 mb-0">Comments :</h5>

                        <ul className="media-list list-unstyled mb-0">
                            {commentData.map((item,index)=>{
                                return(
                                <li className="mt-4" key={index}>
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <Link className="pe-3" to="#">
                                                <img src={item.image} className="img-fluid avatar avatar-md-sm rounded-circle shadow" alt="img"/>
                                            </Link>
                                            <div className="commentor-detail">
                                                <h6 className="mb-0"><Link to="#" className="text-dark media-heading">{item.name}</Link></h6>
                                                <small className="text-muted">{item.date}</small>
                                            </div>
                                        </div>
                                        <Link to="#" className="text-muted"><i className="mdi mdi-reply"></i> Reply</Link>
                                    </div>
                                    <div className="mt-3">
                                        <p className="text-muted font-italic p-3 bg-light rounded">{item.desc}</p>
                                    </div>
                                </li>
                                )
                            })}
                        </ul>
                    
                        <h5 className="card-title mt-4 mb-0">Leave A Comment :</h5>

                        <form className="mt-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Your Comment</label>
                                        <textarea id="message" placeholder="Your Comment" rows="5" name="message" className="form-control" required=""></textarea>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="form-label">Name <span className="text-danger">*</span></label>
                                        <input id="name" name="name" type="text" placeholder="Name" className="form-control" required=""/>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="form-label">Your Email <span className="text-danger">*</span></label>
                                        <input id="email" type="email" placeholder="Email" name="email" className="form-control" required=""/>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="send d-grid">
                                        <button type="submit" className="btn btn-primary">Send Message</button>
                                    </div>
                                </div>
                            </div>
                        </form> */}


                        </div>

                        <div className="col-lg-4 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="card border-0 sidebar sticky-bar rounded shadow">
                                <div className="card-body">
                                    <div className="widget mb-4 pb-2">
                                        <h5 className="widget-title">Procurar</h5>
                                        <div id="search2" className="widget-search mt-4 mb-0">
                                            <form role="search" method="get" id="searchform" className="searchform">
                                                <div>
                                                    <input type="text" className="border rounded form-control" name="s" id="s" placeholder="procure..." />
                                                    <input type="submit" id="searchsubmit" value="Procurar" />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="widget mb-4 pb-2">
                                        <h5 className="widget-title">Noticias Recentes</h5>
                                        <div className="mt-4">
                                            {info[0]?.outrasNoticias?.slice(0, 3).map((item, index) => {
                                                return (
                                                    <div className="clearfix post-recent" key={index}>
                                                        <div className="post-recent-thumb float-start"> <Link to={"/blog-detail/" + item?.id}> <img alt="" src={url_root + "/storage/" + item.imagem} className="img-fluid rounded" /></Link></div>
                                                        <div className="post-recent-content float-start"><Link to={"/blog-detail/" + item?.id}>{item.titulo}</Link><span className="text-muted mt-2">{item.descricao}</span></div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="widget mb-4 pb-2">
                                        <h5 className="widget-title">Tags Nuvem</h5>
                                        <div className="tagcloud mt-4">
                                            {/* <Link to="#" className="rounded">Negocio</Link>
                                            <Link to="#" className="rounded">Finança</Link>
                                            <Link to="#" className="rounded">Marketing</Link>
                                            <Link to="#" className="rounded">Moda</Link>

                                            <Link to="#" className="rounded">Beleza</Link>
                                            <Link to="#" className="rounded">Video</Link>
                                            <Link to="#" className="rounded">Audio</Link> */}
                                        </div>
                                    </div>
                                    <div className="widget">
                                        <h5 className="widget-title">Siga-nos</h5>
                                        <ul className="list-unstyled social-icon social mb-0 mt-4">
                                            <li className="list-inline-item"><Link to="#" className="rounded"><FiFacebook className="fea icon-sm fea-social" /></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded"><FiInstagram className="fea icon-sm fea-social" /></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded"><FiTwitter className="fea icon-sm fea-social" /></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded"><FiLinkedin className="fea icon-sm fea-social" /></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded"><FiGithub className="fea icon-sm fea-social" /></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded"><FiYoutube className="fea icon-sm fea-social" /></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded"><FiGitlab className="fea icon-sm fea-social" /></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="container mt-100 mt-60">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <h4 className="title mb-0">Outras Noticias:</h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 mt-4 pt-2">
                            <div className="slider-range-three">
                                <TinySlider settings={settings}>
                                    {outrasNoticias?.slice(3, 20).map((item, index) => {
                                        return (
                                            <div className="tiny-slide">
                                            <div className="card blog blog-primary border-0 shadow rounded overflow-hidden m-1" key={index}>
                                                <img src={item.image} className="img-fluid" alt=""/>
                                                <div className="card-body p-4">
                                                <ul className="list-unstyled mb-2">
                                                    <li className="list-inline-item text-muted small me-3 d-inline-flex align-items-center"><FiCalendar className="mb-0 text-dark h6 me-1"/>{item.date}</li>
                                                    <li className="list-inline-item text-muted small d-inline-flex align-items-center"><FiClock className="mb-0 text-dark h6 me-1"/>{item.time}</li>
                                                </ul>
                                                    <Link to={`/blog-detail/${item.id}`} className="text-dark title h5">{item.title}</Link>
                                                    <div className="post-meta d-flex justify-content-between mt-3">
                                                        <ul className="list-unstyled mb-0">
                                                            <li className="list-inline-item me-2 mb-0"><Link to="#" className="text-muted like"><i className="mdi mdi-heart-outline me-1"></i>33</Link></li>
                                                            <li className="list-inline-item"><Link to="#" className="text-muted comments"><i className="mdi mdi-comment-outline me-1"></i>08</Link></li>
                                                        </ul>
                                                        <Link to={`/blog-detail/${item.id}`} className="link">Read More <i className="mdi mdi-chevron-right align-middle"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    })}
                                </TinySlider>
                            </div>
                        </div>
                    </div>

                </div> */}


            </section>

            <Footer footer={info[0]?.footer} consultas={info[0]?.consultas} />
            <ScrollTop />




            {
                loading ? <>
                    <Spinner />
                </> : <></>
            }

        </>
    )
}