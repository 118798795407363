/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import AuthUser from "../components/AuthUser";
import Spinner from "../components/Spinner";

import { Link } from "react-router-dom";

import bg1 from "../assets/images/bg/noticiais.jpeg"

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";

import { blogData } from "../data/data";
import {FiCalendar, FiClock} from '../assets/icons/vander'

export default function Blogs(){

    const { http, url_root } = AuthUser();
    
  const [unidades, setUnidades] = useState([]);
    const [doctores, setDoctores] = useState([]);
  
    const [bgBackup, setBG] = useState(bg1);
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
      // Lógica para carregar cursos apenas se a permissão permitir
  
      const fetchData = async () => {
        try {
          // Chama a função assíncrona
  
          await getInfo();
  
        } catch (error) {
          setLoading(false);
          console.error('Erro ao obter info:', error);
        }
      };
  
      // Chama a função fetchData() para iniciar o processo
      fetchData();
  
    }, []);
  
  
    useEffect(() => {
      console.log('Unidades:', unidades);
      setUnidades(info[0]?.unidades?.slice(0, 14) || []);
      setDoctores(info[0]?.doctores?.slice(0, 14) || []);
    }, [info]);
  
  
    const getInfo = async () => {
  
      try {
  
        setLoading(true); // Set loading to true when the request starts
  
        const res = await http.get('/site-pagina/7');
  
        console.log("info :");
        console.log(res.data);
  
        setInfo(res.data);
  
        console.log('Doctores');
        console.log(info[0]?.doctores);
        console.log('Consultas');
        console.log(info[0]?.consultas);
  
        setBG(url_root + "/storage/" + info[0]?.banner?.imagem);
  
        setUnidades(info[0]?.unidades?.slice(0, 14) || []);
        setDoctores(info[0]?.doctores?.slice(0, 14) || []);
  
        setLoading(false);
  
      } finally {
        setLoading(false); // Set loading to false when the request is complete (either success or error)
      }
    };

    
    return(
        <>
        <Navbar manuClass="navigation-menu nav-light nav-left" containerClass="container"/>


        {info[0]?.banner != null ? <>
            <section className="bg-half-170 d-table w-100" style={{backgroundImage:`url(${url_root + "/storage/" + info[0]?.banner?.imagem})`, backgroundPosition:'center'}}>
            <div className="bg-overlay bg-overlay-dark"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="section-title text-center">
                            <h3 className="sub-title mb-4 text-white title-dark">{info[0]?.banner?.designacao}{" "}</h3>
                            <p className="para-desc mx-auto text-white-50">{info[0]?.banner?.descricao}{" "}</p>
                        
                            <nav aria-label="breadcrumb" className="d-inline-block mt-3">
                                <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                                    <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Noticias</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
	</> : <></>}

      
        <div className="position-relative">
            <div className="shape overflow-hidden text-color-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>

        <section className="section">
            <div className="container">
                <div className="row">
                    {info[0]?.noticias?.slice(0,15).map((item,index) =>{
                        return(
                        <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2" key={index}>
                            <div className="card blog blog-primary border-0 shadow rounded overflow-hidden">
                                <img src={url_root+"/storage/"+item.imagem} className="img-fluid"  alt=""/>
                                <div className="card-body p-4">
                                    <ul className="list-unstyled mb-2">
                                        <li className="list-inline-item text-muted small me-3 d-inline-flex align-items-center"><FiCalendar className="mb-0 text-dark h6 me-1"/>{item.descricao}</li>
                                        <li className="list-inline-item text-muted small d-inline-flex align-items-center"><FiClock className="mb-0 text-dark h6 me-1"/>5 min</li>
                                    </ul>
                                    <Link to={`/blog-detail/${item.id}`} className="text-dark title h5">{item.titulo}</Link>
                                    <div className="post-meta d-flex justify-content-between mt-3">
                                    <ul className="list-unstyled mb-0">
                                            {/* <li className="list-inline-item me-2 mb-0"><Link to="#" className="text-muted like"><i className="mdi mdi-heart-outline me-1"></i>{item.like}</Link></li> */}
                                            {/* <li className="list-inline-item"><Link to="#" className="text-muted comments"><i className="mdi mdi-comment-outline me-1"></i>{item.comment}</Link></li> */}
                                        </ul>
                                        <Link to={`/blog-detail/${item.id}`} className="link">Ler mais <i className="mdi mdi-chevron-right align-middle"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                    })}
                </div>

                {/* <div className="row text-center">
                    <div className="col-12">
                        <ul className="pagination justify-content-center mb-0 list-unstyled">
                            <li className="page-item"><Link className="page-link" to="#" aria-label="Previous">Anterior</Link></li>
                            <li className="page-item active"><Link className="page-link" to="#">1</Link></li>
                            <li className="page-item"><Link className="page-link" to="#">2</Link></li>
                            <li className="page-item"><Link className="page-link" to="#">3</Link></li>
                            <li className="page-item"><Link className="page-link" to="#" aria-label="Next">Proximo</Link></li>
                        </ul>
                    </div>
                </div> */}


            </div>
        </section>
        <Footer footer={info[0]?.footer} consultas={info[0]?.consultas} />
        <ScrollTop/>

        {
        loading ? <>
          <Spinner />
        </> : <></>
      }

        </>
    )
}