/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import AuthUser from "../../components/AuthUser";
import Spinner from "../../components/Spinner";

import { Link } from "react-router-dom";
import Navbar from "../../components/navbar";
import { doctorData } from "../../data/data";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";

import bg1 from '../../assets/images/bg/especialistas.jpeg'

import {FiHeart, RiMapPinLine, RiTimeLine, RiMoneyDollarCircleLine, FiFacebook, FiLinkedin, FiGithub, FiTwitter,  FiEye} from '../../assets/icons/vander'

export default function DoctorTeamTwo(){

    const { http, url_root } = AuthUser();

  
  const [unidades, setUnidades] = useState([]);
    const [doctores, setDoctores] = useState([]);
  
    const [bgBackup, setBG] = useState(bg1);
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
      // Lógica para carregar cursos apenas se a permissão permitir
  
      const fetchData = async () => {
        try {
          // Chama a função assíncrona
  
          await getInfo();
  
        } catch (error) {
          setLoading(false);
          console.error('Erro ao obter info:', error);
        }
      };
  
      // Chama a função fetchData() para iniciar o processo
      fetchData();
  
    }, []);
  
  
    useEffect(() => {
      console.log('Unidades:', unidades);
      setUnidades(info[0]?.unidades?.slice(0, 14) || []);
      setDoctores(info[0]?.doctores?.slice(0, 14) || []);
    }, [info]);
  
  
    const getInfo = async () => {
  
      try {
  
        setLoading(true); // Set loading to true when the request starts
  
        const res = await http.get('/site-pagina/3');
  
        console.log("info :");
        console.log(res.data);
  
        setInfo(res.data);
  
        console.log('Doctores');
        console.log(info[0]?.doctores);
        console.log('Consultas');
        console.log(info[0]?.consultas);
  
        setBG(url_root + "/storage/" + info[0]?.banner?.imagem);
  
        setUnidades(info[0]?.unidades?.slice(0, 14) || []);
        setDoctores(info[0]?.doctores?.slice(0, 14) || []);
  
        setLoading(false);
  
      } finally {
        setLoading(false); // Set loading to false when the request is complete (either success or error)
      }
    };

    return(
        <>
        <Navbar manuClass="navigation-menu nav-light nav-left" containerClass="container" />


        {info[0]?.banner != null ? <>
            <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${url_root + "/storage/" + info[0]?.banner?.imagem})`, backgroundPosition: 'center' }}>
        <div className="bg-overlay bg-overlay-dark"></div>
            <div className="container">
                <div className="row mt-5 justify-content-center">
                    <div className="col-12">
                        <div className="section-title text-center">
                            <h3 className="sub-title mb-4 text-white title-dark">{info[0]?.banner?.designacao}{" "}</h3>
                            <p className="para-desc mx-auto text-white-50">{info[0]?.banner?.descricao}{" "}</p>
                        
                            <nav aria-label="breadcrumb" className="d-inline-block mt-3">
                                    <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                                        <li className="breadcrumb-item"><Link to="/">INICIO</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Especialistas</li>
                                    </ul>
                                </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
	</> : <></>}

       

        <section className="section">
            <div className="container">
                <div className="row align-items-center">
                    {info[0]?.doctores?.map((item, index) =>{
                        return(
                        <div className="col-xl-3 col-lg-3 col-md-6 mt-4 pt-2" key={index}>
                            <div className="card team border-0 rounded shadow overflow-hidden">
                                <div className="team-person position-relative overflow-hidden">
                                    <img src={url_root+"/storage/"+item.fotografia} className="img-fluid" alt=""/>
                                    <ul className="list-unstyled team-like">
                                        <li><Link to="#" className="btn btn-icon btn-pills btn-soft-danger"><FiHeart className="icons"/></Link></li>
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <Link to={"/doctor-profile/"+item.id} className="title text-dark h5 d-block mb-0">{item.name}</Link>
                                    <small className="text-muted speciality">{item.nivel_academico}</small>
                                    <div className="d-flex justify-content-between align-items-center mt-2">
                                      
                                    </div>
                                    <ul className="list-unstyled mt-2 mb-0">
                                       
                                    </ul>
                                    <ul className="list-unstyled mt-2 mb-0">
                                        <li className="list-inline-item"><Link to="#" className="btn btn-icon btn-pills btn-soft-primary"><FiFacebook className="icons"/></Link></li>
                                        <li className="mt-2 list-inline-item"><Link to="#" className="btn btn-icon btn-pills btn-soft-primary"><FiLinkedin className="icons"/></Link></li>
                                       
                                        <li className="mt-2 list-inline-item"><Link to="#" className="btn btn-icon btn-pills btn-soft-primary"><FiTwitter className="icons"/></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        )
                    })}
                    
                </div>
            </div>
        </section>
        <Footer footer={info[0]?.footer} consultas={info[0]?.consultas} />
        <ScrollTop/>

        {
        loading ? <>
          <Spinner />
        </> : <></>
      }

        </>
    )
}