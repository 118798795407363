/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import AuthUser from "../components/AuthUser";
import Spinner from "../components/Spinner";

import { Link, useParams } from "react-router-dom";

import bg1 from "../assets/images/bg/pharm01.jpg"

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";

import { blogData } from "../data/data";
import { FiCalendar, FiClock, FiLinkedin, FiTwitter, FiHeart, FiEye, FiFacebook } from '../assets/icons/vander'

export default function Pesquisa() {

    const { termo } = useParams();

    const { http, url_root } = AuthUser();

    const [consultas, setConsultas] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [doctores, setDoctores] = useState([]);
    const [noticias, setNoticias] = useState([]);

    const [bgBackup, setBG] = useState(bg1);
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Lógica para carregar cursos apenas se a permissão permitir

        const fetchData = async () => {
            try {
                // Chama a função assíncrona

                await getInfo();

            } catch (error) {
                setLoading(false);
                console.error('Erro ao obter info:', error);
            }
        };

        // Chama a função fetchData() para iniciar o processo
        fetchData();

    }, []);


    useEffect(() => {
        console.log('Unidades:', unidades);
        setUnidades(info[0]?.unidades || []);
        setDoctores(info[0]?.doctores || []);
        setConsultas(info[0]?.consultas || []);
        setNoticias(info[0]?.noticias || []);
    }, [info, termo]);


    const getInfo = async () => {

        try {

            setLoading(true); // Set loading to true when the request starts

            const res = await http.get('/site-pesquisa/' + termo);

            setInfo(res.data);

            setUnidades(info[0]?.unidades || []);
            setDoctores(info[0]?.doctores || []);
            setConsultas(info[0]?.consultas || []);
            setNoticias(info[0]?.noticias || []);

            setLoading(false);

        } finally {
            setLoading(false); // Set loading to false when the request is complete (either success or error)
        }
    };


    return (
        <>
            <Navbar manuClass="navigation-menu nav-light nav-left" containerClass="container" />



            <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'center' }}>
                <div className="bg-overlay bg-overlay-dark"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="section-title text-center">
                                <h3 className="sub-title mb-4 text-white title-dark">Termo da pesquisa</h3>
                                <p className="para-desc mx-auto text-white-50">{termo}{" "}</p>

                                <nav aria-label="breadcrumb" className="d-inline-block mt-3">
                                    <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                                        <li className="breadcrumb-item"><Link to="/">INICIO</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Pesquisa</li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="position-relative">
                <div className="shape overflow-hidden text-color-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="section">
                <div className="container">

                    <div className="row">
                        {consultas?.map((item, index) => {
                            return (
                                <div className="col-xl-3 col-lg-4 col-md-6 col-6 mt-4 pt-2" key={index}>
                                    <Link to={"/servico-detail/" + item?.id} className="card pharpachy-categories border-0 rounded overflow-hidden">
                                        <img src={url_root + "/storage/" + item.imagem} className="img-fluid" alt="" />
                                        <div className="category-title">
                                            <span className="text-dark title-white"><span className="h5">{item?.designacao}</span><br />{item?.categoria}</span>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}
                    </div>

                    <div className="row mt-60 mt-100">
                        {unidades?.map((item, index) => {
                            return (
                                <div className="col-lg-3 col-md-6 col-12 mt-4 pt-2" key={index}>
                                    <div className="card shop-list border-0">
                                        <ul className="label list-unstyled mb-0">
                                            {/* <li><Link to="" className="badge rounded-pill badge-success">Featured</Link></li> */}
                                        </ul>
                                        <div className="shop-image position-relative overflow-hidden rounded shadow">
                                            <Link to={"/pharmacy-product-detail/" + item.id}><img src={url_root + "/storage/" + item.imagem} className="img-fluid" alt="" /></Link>
                                            <ul className="list-unstyled shop-icons">
                                                <li><Link to="#" className="btn btn-icon btn-pills btn-soft-danger"><FiHeart className="icons" /></Link></li>
                                                <li className="mt-2"><Link to="/pharmacy-product-detail" className="btn btn-icon btn-pills btn-soft-primary"><FiEye className="icons" /></Link></li>
                                                {/* <li className="mt-2"><Link to="/pharmacy-shop-cart" className="btn btn-icon btn-pills btn-soft-warning"><FiShoppingCart className="icons" /></Link></li> */}
                                            </ul>

                                            {/* <Counter /> */}
                                        </div>
                                        <div className="card-body content pt-4 p-2">
                                            <Link to={"/pharmacy-product-detail/" + item.id} className="text-dark product-name h6">{item?.designacao}</Link>
                                            <div className="d-flex justify-content-between mt-1">
                                                <h6 className="text-muted small font-italic mb-0 mt-1">{item?.price}</h6>
                                                <ul className="list-unstyled text-warning mb-0">
                                                    <li className="list-inline-item">
                                                        <div className="icon text-center rounded-md">
                                                            <i className="fa fa-user" />
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="row align-items-center mt-60 mt-100">
                        {doctores?.map((item, index) => {
                            return (
                                <div className="col-xl-3 col-lg-3 col-md-6 mt-4 pt-2" key={index}>
                                    <div className="card team border-0 rounded shadow overflow-hidden">
                                        <div className="team-person position-relative overflow-hidden">
                                            <img src={url_root + "/storage/" + item.fotografia} className="img-fluid" alt="" />
                                            <ul className="list-unstyled team-like">
                                                <li><Link to="#" className="btn btn-icon btn-pills btn-soft-danger"><FiHeart className="icons" /></Link></li>
                                            </ul>
                                        </div>
                                        <div className="card-body">
                                            <Link to={"/doctor-profile/" + item.id} className="title text-dark h5 d-block mb-0">{item.name}</Link>
                                            <small className="text-muted speciality">{item.nivel_academico}</small>
                                            <div className="d-flex justify-content-between align-items-center mt-2">

                                            </div>
                                            <ul className="list-unstyled mt-2 mb-0">

                                            </ul>
                                            <ul className="list-unstyled mt-2 mb-0">
                                                <li className="list-inline-item"><Link to="#" className="btn btn-icon btn-pills btn-soft-primary"><FiFacebook className="icons" /></Link></li>
                                                <li className="mt-2 list-inline-item"><Link to="#" className="btn btn-icon btn-pills btn-soft-primary"><FiLinkedin className="icons" /></Link></li>

                                                <li className="mt-2 list-inline-item"><Link to="#" className="btn btn-icon btn-pills btn-soft-primary"><FiTwitter className="icons" /></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>

                    <div className="row mt-60 mt-100">
                        {noticias?.map((item, index) => {
                            return (
                                <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2" key={index}>
                                    <div className="card blog blog-primary border-0 shadow rounded overflow-hidden">
                                        <img src={url_root + "/storage/" + item.imagem} className="img-fluid" alt="" />
                                        <div className="card-body p-4">
                                            <ul className="list-unstyled mb-2">
                                                <li className="list-inline-item text-muted small me-3 d-inline-flex align-items-center"><FiCalendar className="mb-0 text-dark h6 me-1" />{item.descricao}</li>
                                                <li className="list-inline-item text-muted small d-inline-flex align-items-center"><FiClock className="mb-0 text-dark h6 me-1" />5 min</li>
                                            </ul>
                                            <Link to={`/blog-detail/${item.id}`} className="text-dark title h5">{item.titulo}</Link>
                                            <div className="post-meta d-flex justify-content-between mt-3">
                                                <ul className="list-unstyled mb-0">
                                                    {/* <li className="list-inline-item me-2 mb-0"><Link to="#" className="text-muted like"><i className="mdi mdi-heart-outline me-1"></i>{item.like}</Link></li> */}
                                                    {/* <li className="list-inline-item"><Link to="#" className="text-muted comments"><i className="mdi mdi-comment-outline me-1"></i>{item.comment}</Link></li> */}
                                                </ul>
                                                <Link to={`/blog-detail/${item.id}`} className="link">Ler mais <i className="mdi mdi-chevron-right align-middle"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                </div>
            </section >


            <Footer footer={info[0]?.footer} consultas={info[0]?.consultas} />
            <ScrollTop />

            {
                loading ? <>
                    <Spinner />
                </> : <></>
            }

        </>
    )
}