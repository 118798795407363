/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import AuthUser from "../components/AuthUser";
import Spinner from "../components/Spinner";

import blog from '../assets/images/blog/single.jpg'

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";

import bg1 from '../assets/images/bg/05.jpg'

import { blogData, commentData, recentPost, pharmaCategories, contactData } from "../data/data";

import { FiFacebook, FiInstagram, FiTwitter, FiLinkedin, FiGithub, FiGitlab, FiYoutube, FiCalendar, FiClock, MdOutlineEventNote } from '../assets/icons/vander'

import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

import MarcarConsulta from "../components/MarcarConsulta";

import { SnackbarProvider, enqueueSnackbar, useSnackbar } from 'notistack';

const ServicoDetail = () => {

    const { id } = useParams();

    const { http, url_root } = AuthUser();

    const [unidades, setUnidades] = useState([]);
    const [doctores, setDoctores] = useState([]);
    const [paragrafos, setParagrafos] = useState([]);
    const [consultas, setConsultas] = useState([]);
    const [unidade, setUnidade] = useState([]);

    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Lógica para carregar cursos apenas se a permissão permitir

        const fetchData = async () => {
            try {
                // Chama a função assíncrona

                await getInfo();

            } catch (error) {
                setLoading(false);
                console.error('Erro ao obter info:', error);
            }
        };

        // Chama a função fetchData() para iniciar o processo
        fetchData();

    }, [id]);


    useEffect(() => {
        setConsultas(info[0]?.consultas || []);
        setUnidades(info[0]?.unidades || []);
        setUnidade(info[0]?.unidade || {});
        setParagrafos(info[0]?.paragrafos.slice(0, 14) || []);
    }, [info]);


    const getInfo = async () => {

        try {

            setLoading(true); // Set loading to true when the request starts

            const res = await http.get('/site-pagina-single/6/' + id);

            console.log("info :");
            console.log(res.data);


            setInfo(res.data);

            setConsultas(info[0]?.consultas || []);
            setUnidades(info[0]?.unidades?.slice(0, 14) || []);
            setDoctores(info[0]?.doctores?.slice(0, 14) || []);
            setUnidade(info[0]?.unidade || {});
            setParagrafos(info[0]?.paragrafos.slice(0, 14) || []);
            setUnidades(info[0]?.unidades || []);

            setLoading(false);

        } finally {
            setLoading(false); // Set loading to false when the request is complete (either success or error)
        }
    };

    let settings = {
        container: '.slider-range-three',
        items: 3,
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 400,
        gutter: 24,
        responsive: {
            992: {
                items: 3
            },

            767: {
                items: 2
            },

            320: {
                items: 1
            },
        },
    };

    let data = blogData.find((blog) => blog.id === parseInt(id))

    return (
        <>
            <Navbar manuClass="navigation-menu nav-left nav-light" containerClass="container" />

            {info != null ? <>
                <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${url_root + "/storage/" + info[0]?.consulta?.imagem})`, backgroundPosition: 'center' }}>
                    <div className="bg-overlay bg-overlay-dark"></div>
                    <div className="container">
                        <div className="row mt-5 justify-content-center">
                            <div className="col-12">
                                <div className="section-title text-center">
                                    <h3 className="sub-title mb-4 text-white title-dark">{info[0]?.consulta?.designacao}</h3>
                                    <p className="para-desc mx-auto text-white-50">{info[0]?.consulta?.descricao_curta}</p>

                                    <nav aria-label="breadcrumb" className="d-inline-block mt-3">
                                        <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                                            <li className="breadcrumb-item"><Link to="/">INICIO</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Serviço</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </> : <></>}


            <div className="position-relative">
                <div className="shape overflow-hidden text-color-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-lg-7">
                            <img src={url_root + "/storage/" + info[0]?.paragrafos[0]?.imagem} className="img-fluid rounded shadow" alt="" />
                            <p className="text-muted mt-4">{info[0]?.paragrafos[0]?.descricao}</p>


                            {info[0]?.paragrafos?.slice(1, 20).map((item, index) => {
                                return (
                                    <p className="text-muted" key={index}>{item?.descricao}</p>
                                )
                            })}


                            {/* <h5 className="card-title mt-4 mb-0">Comments :</h5>

                        <ul className="media-list list-unstyled mb-0">
                            {commentData.map((item,index)=>{
                                return(
                                <li className="mt-4" key={index}>
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <Link className="pe-3" to="#">
                                                <img src={item.image} className="img-fluid avatar avatar-md-sm rounded-circle shadow" alt="img"/>
                                            </Link>
                                            <div className="commentor-detail">
                                                <h6 className="mb-0"><Link to="#" className="text-dark media-heading">{item.name}</Link></h6>
                                                <small className="text-muted">{item.date}</small>
                                            </div>
                                        </div>
                                        <Link to="#" className="text-muted"><i className="mdi mdi-reply"></i> Reply</Link>
                                    </div>
                                    <div className="mt-3">
                                        <p className="text-muted font-italic p-3 bg-light rounded">{item.desc}</p>
                                    </div>
                                </li>
                                )
                            })}
                        </ul>
                    
                        <h5 className="card-title mt-4 mb-0">Leave A Comment :</h5>

                        <form className="mt-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Your Comment</label>
                                        <textarea id="message" placeholder="Your Comment" rows="5" name="message" className="form-control" required=""></textarea>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="form-label">Name <span className="text-danger">*</span></label>
                                        <input id="name" name="name" type="text" placeholder="Name" className="form-control" required=""/>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="form-label">Your Email <span className="text-danger">*</span></label>
                                        <input id="email" type="email" placeholder="Email" name="email" className="form-control" required=""/>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="send d-grid">
                                        <button type="submit" className="btn btn-primary">Send Message</button>
                                    </div>
                                </div>
                            </div>
                        </form> */}


                        </div>

                        <div className="col-lg-4 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="card border-0 sidebar sticky-bar rounded shadow">
                                <div className="card-body">
                                    <div className="widget mb-4 pb-2">
                                        <h5 className="widget-title">Procurar</h5>
                                        <div id="search2" className="widget-search mt-4 mb-0">
                                            <form role="search" method="get" id="searchform" className="searchform">
                                                <div>
                                                    <input type="text" className="border rounded form-control" name="s" id="s" placeholder="procure..." />
                                                    <input type="submit" id="searchsubmit" value="Procurar" />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    {/* <div className="widget mb-4 pb-2">
                                    <h5 className="widget-title">Artigos Recentes</h5>
                                    <div className="mt-4">
                                        {recentPost.map((item,index)=>{
                                            return(
                                                <div className="clearfix post-recent" key={index}>
                                                    <div className="post-recent-thumb float-start"> <Link to="#"> <img alt="" src={item.image} className="img-fluid rounded"/></Link></div>
                                                    <div className="post-recent-content float-start"><Link to="#">{item.title}</Link><span className="text-muted mt-2">{item.date}</span></div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div> */}
                                    <div className="widget mb-4 pb-2">
                                        <h5 className="widget-title">Tags Nuvem</h5>
                                        <div className="tagcloud mt-4">
                                            <Link to="#" className="rounded">Pele</Link>
                                            <Link to="#" className="rounded">visão</Link>
                                            <Link to="#" className="rounded">Estetica</Link>
                                            <Link to="#" className="rounded">Cabelos</Link>

                                            <Link to="#" className="rounded">Beleza</Link>
                                        </div>
                                    </div>
                                    <div className="widget">
                                        <h5 className="widget-title">Siga-nos</h5>
                                        <ul className="list-unstyled social-icon social mb-0 mt-4">
                                            <li className="list-inline-item"><Link to="#" className="rounded"><FiFacebook className="fea icon-sm fea-social" /></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded"><FiInstagram className="fea icon-sm fea-social" /></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded"><FiLinkedin className="fea icon-sm fea-social" /></Link></li>
                                            <li className="list-inline-item"><Link to="#" className="rounded"><FiYoutube className="fea icon-sm fea-social" /></Link></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="container mt-100 mt-60">
                    <div className="row align-items-lg-end">
                        <div className="col-md-6">
                            <div className="me-xl-3">
                                <div className="section-title mb-4 pb-2">
                                    <MdOutlineEventNote className="text-primary h2" />
                                    <h4 className="title mb-4"> Agende Sua Consulta </h4>{" "}
                                    <p className="text-muted para-desc mb-0">
                                        {" "}
                                        Excelente médico para fornecer assistência imediata, tratamento de emergência ou uma simples consulta para você e sua família.{" "}
                                    </p>{" "}
                                </div>{" "}
                                {contactData.map((item, index) => {
                                    let Icon = item.icon;
                                    return (
                                        <div
                                            className="features feature-bg-primary d-flex card flex-row p-4 rounded-md shadow position-relative overflow-hidden mt-4"
                                            key={index}
                                        >
                                            <Icon className="icons display-3 mb-0 text-primary" />
                                            <div className="ms-3">
                                                <h5 className="titles"> {item.title} </h5>{" "}
                                                <p className="text-muted para mb-0"> {item.desc} </p>{" "}
                                            </div>{" "}
                                            <div className="big-icon">
                                                <Icon />
                                            </div>{" "}
                                        </div>
                                    );
                                })}{" "}
                            </div>{" "}
                        </div>

                        <MarcarConsulta doctores={info[0]?.doctores} consultas={info[0]?.consultas} especialidades={info[0]?.especialistas} />

                    </div>{" "}
                </div>{" "}


                <div className="container mt-100 mt-60">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <h4 className="title mb-0">Outras Consultas:</h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 mt-4 pt-2">
                            <div className="slider-range-four">
                                <TinySlider settings={settings}>
                                    {consultas?.map((item, index) => {
                                        return (
                                            <div className="tiny-slide" key={index}>
                                                <Link to={"/servico-detail/" + item?.id} className="card pharpachy-categories border-0 rounded overflow-hidden">
                                                    <img src={url_root + "/storage/" + item.imagem} className="img-fluid" alt="" />
                                                    <div className="category-title">
                                                        <span className="text-dark title-white"><span className="h5">{item.designacao}</span><br />{item.categoria}</span>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </TinySlider>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <Footer footer={info[0]?.footer} consultas={info[0]?.consultas} />
            <ScrollTop />

            {
                loading ? <>
                    <Spinner />
                </> : <></>
            }


        </>
    )
}

export default function WrappedFooteServicoDetail() {
    return (
        <SnackbarProvider>
            <ServicoDetail />
        </SnackbarProvider>
    );
}