/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import AuthUser from "../../components/AuthUser";
import Spinner from "../../components/Spinner";
import { Link } from "react-router-dom";

import bg1 from '../../assets/images/bg/servico-banner-1.jpeg'
import bg2 from '../../assets/images/bg/servico-banner-2.jpeg'
import bg3 from '../../assets/images/bg/servico-banner-3.jpeg'
import about1 from '../../assets/images/pharmacy/doctor.jpg'
import about2 from '../../assets/images/pharmacy/delivery.jpg'
import download from '../../assets/images/pharmacy/download.png'

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";

import "../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel';

import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

import {
  RiEyeFill, RiPsychotherapyFill, RiStethoscopeFill, RiCapsuleFill, RiMicroscopeFill, RiPulseFill, RiEmpathizeFill, RiMindMap, RiMentalHealthLine, RiAppleLine, RiInfraredThermometerLine, RiCapsuleLine, GrUserPolice, RiMicroscopeLine, TbAmbulance, RiShieldCrossLine, RiBriefcase4Line, FiAirplay, MdOutlineFlip, PiTelegramLogoBold, RiMailStarLine, RiHourglassLine, RiHeartLine, RiHeartPulseLine, RiStethoscopeLine, RiVirusLine, RiDossierLine, RiEye2Line, FiUser, FiMail, VscBook, MdFormatItalic, GiMedicalDrip, FiMapPin, FiBriefcase, LuFileCheck, MdOutlineStorefront, LuAward, LiaFileInvoiceDollarSolid, FiThumbsUp, FiPhone, RiArrowRightLine,
  RiMapPin2Line,
  FiFacebook,
  FiLinkedin,
  FiGithub,
  MdOutlineEventNote,
  FiCalendar,
  FiClock,
  FiHeart, FiEye, RiUser2Line, FiTwitter
} from '../../assets/icons/vander'

import { franchiseProcess, partners, patientsData, pharmaCategories, contactData } from "../../data/data";



import MarcarConsulta from "../../components/MarcarConsulta";


import { SnackbarProvider, enqueueSnackbar, useSnackbar } from 'notistack';



const icons = [
  { nome: 'olhos', icon: RiEyeFill },
  { nome: 'cabeça', icon: RiPsychotherapyFill },
  { nome: 'ostetoscopio', icon: RiStethoscopeFill },
  { nome: 'capsula', icon: RiCapsuleFill },
  { nome: 'microscopio', icon: RiMicroscopeFill },
  { nome: 'cardiologia', icon: RiPulseFill },
  { nome: 'coracao', icon: RiEmpathizeFill },
  { nome: 'celulas', icon: RiMindMap },
  { nome: 'cerebro', icon: RiMentalHealthLine },
  { nome: 'apple', icon: RiAppleLine },
  { nome: 'fratura', icon: RiInfraredThermometerLine },
  { nome: 'calendar', icon: FiCalendar },
  { nome: 'doctor', icon: GrUserPolice },
];




const Pharmacy = () => {

  const { http, url_root } = AuthUser();

  const getIconByName = (name) => {
    const iconObject = icons.find(icon => icon.nome == name);
    return iconObject ? iconObject.icon : null;
  };

  let IconeCalendar = getIconByName("calendar");


  const [unidades, setUnidades] = useState([]);
  const [consultas, setConsultas] = useState([]);
  const [doctores, setDoctores] = useState([]);

  const [bgBackup, setBG] = useState(bg1);
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Lógica para carregar cursos apenas se a permissão permitir

    const fetchData = async () => {
      try {
        // Chama a função assíncrona

        await getInfo();

      } catch (error) {
        setLoading(false);
        console.error('Erro ao obter info:', error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();

  }, []);


  useEffect(() => {
    console.log('Unidades:', unidades);
    setUnidades(info[0]?.unidades?.slice(0, 14) || []);
    setDoctores(info[0]?.doctores?.slice(0, 14) || []);
    setConsultas(info[0]?.consultas?.slice(0, 100) || []);
  }, [info]);


  const getInfo = async () => {

    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/site-pagina/6');

      console.log("info :");
      console.log(res.data);

      setInfo(res.data);

      console.log('Doctores');
      console.log(info[0]?.doctores);
      console.log('Consultas');
      console.log(info[0]?.consultas);

      setBG(url_root + "/storage/" + info[0]?.banner?.imagem);

      setUnidades(info[0]?.unidades?.slice(0, 14) || []);
      setDoctores(info[0]?.doctores?.slice(0, 14) || []);
      setConsultas(info[0]?.consultas?.slice(0, 100) || []);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };


  let settings = {
    container: '.slider-range-four',
    items: 4,
    controls: true,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 2000,
    navPosition: "bottom",
    speed: 400,
    gutter: 24,
    responsive: {
      992: {
        items: 4
      },

      767: {
        items: 2
      },


      320: {
        items: 1
      },
    },
  };
  let settings1 = {
    container: '.client-review-slider',
    items: 1,
    controls: true,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 2000,
    navPosition: "bottom",
    speed: 400,
    gutter: 16,
  };
  return (
    <>
      <Navbar manuClass="navigation-menu nav-left nav-light" containerClass="container" />



      {info[0]?.banner != null ? <>
        <section className="home-slider position-relative">
          <Carousel autoPlay={true} infiniteLoop={true} interval={5000} showArrows={false} showStatus={false}>
            
            <div className="carousel-item active">
              <div className="bg-half-260 d-table align-items-center w-100" style={{ backgroundImage: `url(${url_root + "/storage/" + info[0]?.banner[0]?.imagem})`, backgroundPosition: 'center' }}>
                <div className="bg-overlay bg-overlay-dark"></div>
                <div className="container">
                  <div className="row justify-content-center mt-5">
                    <div className="col-lg-12 text-center">
                      <div className="heading-title">
                        <p className="text-white-50">UNIDADE SANITARIA</p>
                        <h1 className="fw-bold text-white title-dark mb-4">{info[0]?.banner[0]?.designacao}</h1>
                        <p className="para-desc mx-auto text-white-50 mb-0">{info[0]?.banner[0]?.descricao}</p>

                        <div className="mt-4 pt-2">
                          <Link to="#" className="btn btn-primary">Ver Mais</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          </Carousel>
        </section>
      </> : <></>}


      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title mb-4 pb-2">
                <h4 className="title mb-4">{info[0]?.paragrafos[0]?.titulo}</h4>
                <p className="text-muted para-desc mb-0">{info[0]?.paragrafos[0]?.descricao}</p>
              </div>
            </div>
          </div>

          <div className="row">
            {consultas?.map((item, index) => {
              return (
                <div className="col-xl-3 col-lg-4 col-md-6 col-6 mt-4 pt-2" key={index}>
                  <Link to={"/servico-detail/"+item?.id} className="card pharpachy-categories border-0 rounded overflow-hidden">
                    <img src={url_root + "/storage/" + item.imagem} className="img-fluid" alt="" />
                    <div className="category-title">
                      <span className="text-dark title-white"><span className="h5">{item?.designacao}</span><br />{item?.categoria}</span>
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>



        </div>

    


        <div className="container mt-100 mt-60">
          <div className="row align-items-lg-end">
            <div className="col-md-6">
              <div className="me-xl-3">
                <div className="section-title mb-4 pb-2">
                  <MdOutlineEventNote className="text-primary h2" />
                  <h4 className="title mb-4"> Agende Sua Consulta </h4>{" "}
                  <p className="text-muted para-desc mb-0">
                    {" "}
                    Excelente médico para fornecer assistência imediata, tratamento de emergência ou uma simples consulta para você e sua família.{" "}
                  </p>{" "}
                </div>{" "}
                {contactData.map((item, index) => {
                  let Icon = item.icon;
                  return (
                    <div
                      className="features feature-bg-primary d-flex card flex-row p-4 rounded-md shadow position-relative overflow-hidden mt-4"
                      key={index}
                    >
                      <Icon className="icons display-3 mb-0 text-primary" />
                      <div className="ms-3">
                        <h5 className="titles"> {item.title} </h5>{" "}
                        <p className="text-muted para mb-0"> {item.desc} </p>{" "}
                      </div>{" "}
                      <div className="big-icon">
                        <Icon />
                      </div>{" "}
                    </div>
                  );
                })}{" "}
              </div>{" "}
            </div>

            <MarcarConsulta doctores={info[0]?.doctores} consultas={info[0]?.consultas} especialidades={info[0]?.especialistas} />


          </div>{" "}
        </div>{" "}


     

      </section>
      <Footer footer={info[0]?.footer} consultas={info[0]?.consultas} />
      <ScrollTop />

      {
        loading ? <>
          <Spinner />
        </> : <></>
      }

    </>
  )
}


export default function WrappedFooterPharmacy() {
  return (
    <SnackbarProvider>
      <Pharmacy />
    </SnackbarProvider>
  );
}